var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('modal',{ref:"dollarActionModal",attrs:{"className":"w-full md:w-2/5 xl:w-2/5 p-10"}},[_c('div',[_c('div',{staticClass:"mb-10"},[_c('div',{staticClass:"text-xl items-center font-bold mb-10"},[_vm._v("Action")]),(_vm.dollarSavings.selected)?_c('div',[_c('div',{staticClass:"flex flex-wrap gap-2 items-center"},[_c('router-link',{staticClass:"btn btn-blue",attrs:{"to":{
                name: 'staff-view',
                params: { staffId: _vm.dollarSavings.selected.user_id },
              },"target":"_blank"}},[_c('ion-icon',{staticClass:"text-xl",attrs:{"name":"body-outline"}}),_c('span',{staticClass:"ml-2"},[_vm._v("View Profile")])],1),_c('router-link',{staticClass:"btn btn-yellow",attrs:{"to":{
                name: 'dollar-saving-history',
                params: {
                  userId: _vm.dollarSavings.selected.user_id,
                  savingId: _vm.dollarSavings.selected.id,
                },
              }}},[_c('ion-icon',{staticClass:"text-xl",attrs:{"name":"briefcase-outline"}}),_c('span',{staticClass:"ml-2"},[_vm._v("View Savings History")])],1)],1)]):_vm._e()])])]),_c('div',{staticClass:"border border-solid border-blue-200"},[_c('datatable',{ref:"table",attrs:{"data":_vm.dollarSavings.data,"columns":_vm.dollarSavings.columns,"filters":_vm.dollarSavings.filters,"query":_vm.dollarSavings.query,"loading":_vm.dollarSavings.loading,"ajax":true,"ajax-pagination":true,"url":((this.$baseurl) + "/admin/savings/dollar"),"search-field":_vm.searchField,"on-click":_vm.click},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('search-form',{staticClass:"mb-5",attrs:{"placeholder":"Search for user's name, ID, savings's id, phone number"},on:{"submit":_vm.reloadData},model:{value:(_vm.dollarSavings.query),callback:function ($$v) {_vm.$set(_vm.dollarSavings, "query", $$v)},expression:"dollarSavings.query"}}),_c('div',{staticClass:"flex items-center mb-5"},[_vm._l((_vm.dollarSavings.filters),function(filter,filterIndex){return [_c('div',{key:filterIndex,staticClass:"processor-filter-item",class:{ active: _vm.currentFilter === filter },on:{"click":function($event){$event.preventDefault();return _vm.setFilter(filter)}}},[_vm._v(" "+_vm._s(filter.title)+" ")])]})],2)]},proxy:true}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col text-14 mb-5 mt-2 font-bold"},[_c('h2',{staticClass:"text-2xl font-bold"},[_vm._v("Dollar Savings")])])}]

export { render, staticRenderFns }