<template>
  <div>
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold">
      <h2 class="text-2xl font-bold">Dollar Savings</h2>
    </div>

    <modal className="w-full md:w-2/5 xl:w-2/5 p-10" ref="dollarActionModal">
      <div>
        <div class="mb-10">
          <div class="text-xl items-center font-bold mb-10">Action</div>

          <div v-if="dollarSavings.selected">
            <div class="flex flex-wrap gap-2 items-center">
              <router-link
                :to="{
                  name: 'staff-view',
                  params: { staffId: dollarSavings.selected.user_id },
                }"
                target="_blank"
                class="btn btn-blue"
              >
                <ion-icon name="body-outline" class="text-xl"></ion-icon>
                <span class="ml-2">View Profile</span>
              </router-link>

              <router-link
                :to="{
                  name: 'dollar-saving-history',
                  params: {
                    userId: dollarSavings.selected.user_id,
                    savingId: dollarSavings.selected.id,
                  },
                }"
                class="btn btn-yellow"
              >
                <ion-icon name="briefcase-outline" class="text-xl"></ion-icon>
                <span class="ml-2">View Savings History</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <div class="border border-solid border-blue-200">
      <datatable
        :data="dollarSavings.data"
        :columns="dollarSavings.columns"
        :filters="dollarSavings.filters"
        :query="dollarSavings.query"
        :loading="dollarSavings.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/savings/dollar`"
        :search-field="searchField"
        :on-click="click"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="dollarSavings.query"
            class="mb-5"
            placeholder="Search for user's name, ID, savings's id, phone number"
            @submit="reloadData"
          />

          <div class="flex items-center mb-5">
            <template v-for="(filter, filterIndex) in dollarSavings.filters">
              <div
                :key="filterIndex"
                class="processor-filter-item"
                :class="{ active: currentFilter === filter }"
                @click.prevent="setFilter(filter)"
              >
                {{ filter.title }}
              </div>
            </template>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
    searchField: {
      type: [HTMLInputElement],
      default: null,
    },
  },
  data() {
    return {
      checkmark: require('@/assets/checkmark-base.svg'),
      currentFilter: null,
      dollarSavings: this.$options.resource([], {
        query: '',
        filters: [
          { name: 'all', title: 'All' },
          { name: 'active', title: 'Active' },
          { name: 'liquidated', title: 'Liquidated' },
          { name: 'withdrawn', title: 'Withdrawn' },
          { name: 'pending', title: 'Pending' },
          { name: 'completed', title: 'Completed' },
        ],
        selected: null,
        columns: [
          {
            name: 'user_name',
            th: 'User Name',
            render: (saving) =>
              `${saving?.first_name} ${saving?.last_name || 'N/A'}`,
          },
          {
            name: 'user_id',
            th: 'User Id',
          },

          {
            name: 'phone',
            th: 'Phone Number',
          },

          {
            name: 'id',
            th: 'Savings ID',
          },
          {
            name: 'frequency',
            th: 'Frequency',
          },
          {
            name: 'amount_to_debit',
            th: 'Amount to Save',
            render: (saving) =>
              `$ ${this.$options.filters.currency(saving.amount_to_debit)}`,
          },
          {
            name: 'funding_source',
            th: 'Payment Methods',
            render: (saving, sources) => sources?.map((source) => source),
          },
          // {
          //   name: 'last_savings_date',
          //   th: 'Last savings date'
          // },
          // {
          //   name: 'withdrawn_at',
          //   th: 'Last withdrawal date'
          // },
          {
            name: 'total_amount_saved',
            th: 'Total amount saved',
            render: (saving) =>
              `$ ${this.$options.filters.currency(saving.amount_saved)}`,
          },
          {
            name: 'total_amount_accrued',
            th: 'Total amount accrued',
            render: (saving) =>
              `$ ${this.$options.filters.currency(saving.total_earnings)}`,
          },
          {
            name: 'total_earnings',
            th: 'Total interest earned',
            render: (saving) =>
              `$ ${this.$options.filters.currency(saving.earnings)}`,
          },
          {
            name: 'status',
            th: 'Status',
            render: (saving, status) => this.flexiStatusCheck(status),
          },
          {
            name: 'created_at',
            th: 'Date Created',
            render: (saving) =>
              this.$moment(saving?.created_at).format(
                'ddd, MMM Do YYYY, h:mm:ss a'
              ),
          },
        ],
      }),
      form: this.$options.basicForm(['status']),
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(saving) {
      this.dollarSavings.selected = saving;
      this.$refs.dollarActionModal.open();
    },
    reloadData() {
      this.$refs.table.loadAjaxData();
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },

    flexiStatusCheck(status) {
      switch (status) {
        case 'closed':
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
                    ${status}
                  </div>`;
        case 'liquidated':
          return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-2 py-1 inline-block">
                    ${status}
                  </div>`;
        case 'running':
        default:
          return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-2 py-1 inline-block">
                    ${status}
                  </div>`;
      }
    },
  },
};
</script>
